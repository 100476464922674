import React from 'react';
import { Button, Icon, Menu, Responsive } from "semantic-ui-react";
import { connect } from 'react-redux';
import { fetchFilters, fetchProducts } from "../../actions";
import _ from 'lodash';
import SearchButton from "./SearchButton";
import SizesButton from "./SizesButton";
import SelectWithImage from "./SelectWithImage";

class Navigation extends React.Component {
    elementsSize = 'massive';
    state = { filters: {}, hamburgerActive: false };

    componentDidMount() {
        this.props.fetchFilters();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filters !== this.props.filters) {
            this.setState({filters: this.props.filters});
        }
    }

    render() {
        return (
            <div>
                <Responsive minWidth={Responsive.onlyLargeScreen.minWidth}>
                    <Menu borderless={true} fixed={'top'} size={this.elementsSize} style={{textAlign: 'center'}}>
                        <Menu.Item>
                            <img src={'/logo.png'} alt={'Natychmiastowo.pl'} />
                        </Menu.Item>
                        {this.renderMenuItems()}
                    </Menu>
                </Responsive>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                    <Menu
                        borderless
                        fixed={'top'}
                        size={this.elementsSize}
                        style={{textAlign: 'center'}}
                        stackable={this.state.hamburgerActive}
                    >
                        <Menu.Item>
                            <img src={'/logo.png'} alt={'Natychmiastowo.pl'} />
                        </Menu.Item>
                        {this.state.hamburgerActive ? this.renderMenuItems() : null}
                        <Menu.Item position={'right'} icon>
                            <Icon
                                name={'bars'}
                                onClick={() => this.setState({hamburgerActive: !this.state.hamburgerActive})}
                                style={{cursor: 'pointer'}}
                            />
                        </Menu.Item>
                    </Menu>
                </Responsive>
            </div>
        );
    };

    renderMenuItems = () => {
        return [
            <Menu.Item key={'search'}>
                <SearchButton size={this.elementsSize}/>
            </Menu.Item>,
            <Menu.Item key={'groups'}>
                <SelectWithImage
                    filters={this.state.filters}
                    type={'group'}
                    entities={this.props.groups}
                    onChange={this.onFiltersChange}
                    triggerName={'Grupa'}
                    size={this.elementsSize}
                />
            </Menu.Item>,
            <Menu.Item key={'brands'}>
                <SelectWithImage
                    filters={this.state.filters}
                    type={'brand'}
                    entities={this.props.brands}
                    onChange={this.onFiltersChange}
                    triggerName={'Marka'}
                    size={this.elementsSize}
                />
            </Menu.Item>,
            <Menu.Item key={'genders'}>
                <SelectWithImage
                    filters={this.state.filters}
                    type={'gender'}
                    entities={this.props.genders}
                    onChange={this.onFiltersChange}
                    triggerName={'Płeć'}
                    size={this.elementsSize}
                />
            </Menu.Item>,
            <Menu.Item key={'sizes'}>
                <SizesButton
                    filters={this.state.filters}
                    onChange={this.onFiltersChange}
                    size={this.elementsSize}
                />
            </Menu.Item>,
            <Menu.Item key={'clear'}>
                <Button
                    fluid
                    onClick={this.onFiltersClear}
                    size={this.elementsSize}
                >Wyczyść</Button>
            </Menu.Item>
        ];
    };

    onFiltersChange = (type, value) => {
        const newFilters = (this.state.filters[type] === value || null === value)
            ? _.omit(this.state.filters, type)
            : {...this.state.filters, [type]: value};

        this.setState({filters: {...newFilters, page: 1}});
        this.props.fetchProducts({...newFilters, page: 1}, true);

        window.scroll(0, 0);
    };

    onFiltersClear = () => {
        this.setState({filters: {page: 1}});
        this.props.fetchProducts({page: 1}, true);

        window.scroll(0, 0);
    }
}

const mapStateToProps = state => {
    return {
        groups: state.groups,
        brands: state.brands,
        genders: state.genders,
        filters: state.filters,
    };
};

const actions = { fetchProducts, fetchFilters };

export default connect(mapStateToProps, actions)(Navigation);
