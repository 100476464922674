import React from 'react';
import {connect} from 'react-redux';
import {fetchProducts} from "../../actions";
import {Button, Form, Icon, Input, Modal} from "semantic-ui-react";

class SearchButton extends React.Component {
    inputRef = React.createRef();
    state = {open: false, searchValue: ''};

    open = () => this.setState({open: true});
    close = () => this.setState({open: false});

    updateInputRef = input => {
        this.inputRef = input;

        if (input) {
            input.focus();
        }
    };

    onInputChange = (e, {value}) => {
        this.setState({searchValue: value});
    };

    onSubmit = () => {
        this.props.fetchProducts({search: this.state.searchValue});
        this.setState({searchValue: ''});
        this.close();
    };

    render() {
        const {filters, size} = this.props;
        const triggerButton = filters.search
            ? <Button size={size} fluid positive><Icon name={'search'}/> {filters.search}</Button>
            : <Button size={size} fluid><Icon name={'search'}/> Szukaj</Button>;

        return (
            <Modal
                open={this.state.open}
                onOpen={this.open}
                onClose={this.close}
                trigger={triggerButton}
                basic
                size={'fullscreen'}
            >
                <Form onSubmit={this.onSubmit}>
                    <Input
                        ref={this.updateInputRef}
                        placeholder={'Użyj skanera lub wpisz'}
                        icon={'search'}
                        size={'massive'}
                        fluid
                        onChange={this.onInputChange}
                        value={this.state.searchValue}
                    />
                </Form>
            </Modal>
        );
    };
}

const mapStateToProps = state => {
    return {filters: state.filters};
};
const actions = {
    fetchProducts
};

export default connect(mapStateToProps, actions)(SearchButton);
