export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const CLEAR_SHIFT = 'CLEAR_SHIFT';
export const CLOSE_PRODUCT_MODAL = 'CLOSE_PRODUCT_MODAL';
export const FETCH_FILTERS = 'FETCH_FILTERS';
export const OPEN_PRODUCT_MODAL = 'OPEN_PRODUCT_MODAL';
export const POST_SHIFT = 'POST_SHIFT';
export const SETUP_DEVICE_KEY = 'SETUP_DEVICE_KEY';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const UPDATE_CURRENT_FILTERS = 'UPDATE_CURRENT_FILTERS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
