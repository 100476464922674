export default (state = null, {type, payload}) => {
    switch (type) {
        case 'OPEN_PRODUCT_MODAL':
            return payload;
        case 'CLOSE_PRODUCT_MODAL':
            return payload;
        default:
            return  state;
    }
};
