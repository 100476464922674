import React from 'react';
import {Card, Image, Label} from "semantic-ui-react";
import {renderPrice} from "../helpers";
import {connect} from 'react-redux';
import {openProductModal} from "../actions";

class ProductCard extends React.Component {
    render() {
        const { product } = this.props;
        const sizes = product.items.map(item => {
            return item.size;
        });

        return (
            <Card onClick={() => this.props.openProductModal(product)}>
                <Label
                    as={'span'}
                    attached={'top right'}
                    color={'blue'}
                    tag
                >{renderPrice(product.price)}</Label>
                <Image
                    src={product.mainImageUrl}
                    ui={false}
                    centered
                    style={{backgroundColor: 'white', objectFit: 'contain', height: '300px'}}
                />
                <Card.Content>
                    <Card.Header>{product.index}</Card.Header>
                    <Card.Description style={{textTransform: 'uppercase'}}>
                        {product.group} {product.producer} {product.name}<br />
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    {sizes.join(' | ')}
                </Card.Content>
            </Card>
        );
    }
}

const mapStateToProps = () => {return {};};
const actions = {openProductModal};

export default connect(mapStateToProps, actions)(ProductCard);
