import React from 'react';
import {connect} from 'react-redux';
import {Button, Header, Icon, Modal} from "semantic-ui-react";

class SizesButton extends React.Component {
    state = {isOpen: false};

    open = () => this.setState({isOpen: true});
    close = () => this.setState({isOpen: false});
    onSizeClick = size => {
        this.props.onChange('size', size);
        this.close();
    };

    render() {
        const {filters, size} = this.props;
        const triggerButton = filters.size
            ? <Button size={size} fluid positive basic>Rozmiar: {filters.size}</Button>
            : <Button size={size} fluid basic>Rozmiar</Button>;

        return (
            <Modal
                open={this.state.isOpen}
                onOpen={this.open}
                onClose={this.close}
                trigger={triggerButton}
            >
                <Modal.Content>
                    <Header>Buty</Header>
                    {this.renderSizeButtons(this.props.sizes.shoes, 'massive')}
                </Modal.Content>
            </Modal>
        );
    }

    renderSizeButtons = (sizes, buttonSize = 'medium') => {
        return sizes.map(shoeSize => {
            const {filters} = this.props;
            const isSelected = filters.size && filters.size === shoeSize;

            return (
                <Button
                    key={shoeSize}
                    basic
                    style={{marginBottom: '5px'}}
                    size={buttonSize}
                    onClick={() => this.onSizeClick(shoeSize)}
                    positive={isSelected}
                >{isSelected ? <Icon name={'checkmark'}/> : ''}{isSelected ? ' ' : ''}{shoeSize}</Button>
            );
        });
    };
}

const mapStateToProps = state => {
    return {
        sizes: state.sizes,
    };
};
const actions = {};

export default connect(mapStateToProps, actions)(SizesButton);
