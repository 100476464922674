import React from 'react';
import ReactDOM from 'react-dom';
import App from "./components/App";
import 'react-alice-carousel/lib/alice-carousel.css';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from "redux";
import reducers from './reducers';
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.querySelector('#root')
);
