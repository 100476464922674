export default (state = null, {type, payload}) => {
    switch (type) {
        case 'POST_SHIFT':
            return payload;
        case 'CLEAR_SHIFT':
            return null;
        default:
            return state;
    }
};
