import React from 'react';
import {Button, Card, Icon, Image, Modal} from "semantic-ui-react";

class SelectWithImage extends React.Component {
    state = {isOpen: false};

    open = () => this.setState({isOpen: true});
    close = () => this.setState({isOpen: false});
    onCardClick = entity => {
        this.props.onChange(this.props.type, entity);
        this.close();
    };

    render() {
        const {filters, type, entities, triggerName, size} = this.props;
        const cards = entities.map(entity => {
            const isSelected = filters[type] && filters[type].id === entity.id;
            const imageUrl = entity.imageUrl
                ? entity.imageUrl
                : 'https://storage.gra5.cloud.ovh.net/v1/AUTH_916e66c29db34f4393e555eb69af2f26/public/images/placeholder.jpg';

            return (
                <Card
                    key={entity.id}
                    onClick={() => this.onCardClick(entity)}
                    color={isSelected ? 'green' : 'grey'}
                >
                    <Image src={imageUrl} alt={entity.name} disabled={isSelected}/>
                    <Card.Content>
                        <Card.Header textAlign={'center'} style={isSelected ? {color: 'cornflowerblue'} : {}}>
                            {isSelected ? <Icon name={'check'} positive/> : null} {entity.name}
                        </Card.Header>
                    </Card.Content>
                </Card>
            );
        });

        const triggerButton = filters[type]
            ? <Button size={size} fluid positive basic>{triggerName}: {filters[type].name}</Button>
            : <Button size={size} fluid basic>{triggerName}</Button>;

        return (
            <Modal
                open={this.state.isOpen}
                onOpen={this.open}
                onClose={this.close}
                trigger={triggerButton}
                size={'fullscreen'}
                basic
            >
                <Button fluid negative basic onClick={() => this.onCardClick(null)} style={{marginBottom: '20px'}}>
                    <Icon name={'close'} />
                </Button>
                <Card.Group centered>{cards}</Card.Group>
            </Modal>
        );
    }
}

export default SelectWithImage;
