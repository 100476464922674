import natshop from "../apis/natshop";
import { POST_SHIFT } from "./types";

export default (itemId, deviceKey) => async dispatch => {
    const response = await natshop.post('/shifts', {
        itemId: itemId,
        key: deviceKey,
    });

    dispatch({
        type: POST_SHIFT,
        payload: {
            itemId: itemId,
            response: response.data,
        }
    });
};
