import Cookie from 'js-cookie';
import nanoid from 'nanoid';
import { SETUP_DEVICE_KEY } from "./types";

export default () => {
    let deviceKey = Cookie.get('device_key');

    if (!deviceKey) {
        deviceKey = nanoid();
        Cookie.set('device_key', deviceKey, { expires: 365000 });
    }

    return { type: SETUP_DEVICE_KEY, payload: deviceKey };
};
