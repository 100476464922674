import setupDeviceKeyAction from "./setupDeviceKeyAction";
import fetchProductsAction from "./fetchProductsAction";
import fetchFiltersAction from "./fetchFiltersAction";
import openProductModalAction from "./openProductModalAction";
import closeProductModalAction from "./closeProductModalAction";
import postShiftAction from "./postShiftAction";
import clearShiftAction from "./clearShiftAction";
import productsLoadingAction from "./productsLoadingAction";
import updateCurrentFiltersAction from "./updateCurrentFiltersAction";
import clearProductsAction from "./clearProductsAction";

export const setupDeviceKey = setupDeviceKeyAction;
export const fetchProducts = fetchProductsAction;
export const fetchFilters = fetchFiltersAction;
export const openProductModal = openProductModalAction;
export const closeProductModal = closeProductModalAction;
export const postShift = postShiftAction;
export const clearShift = clearShiftAction;
export const productsLoading = productsLoadingAction;
export const updateCurrentFilters = updateCurrentFiltersAction;
export const clearProducts = clearProductsAction;
