import { combineReducers } from "redux";
import groupsReducer from "./groupsReducer";
import deviceKeyReducer from "./deviceKeyReducer";
import filtersReducer from "./filtersReducer";
import productsReducer from "./productsReducer";
import brandsReducer from "./brandsReducer";
import gendersReducer from "./gendersReducer";
import productModalReducer from "./productModalReducer";
import shiftReducer from "./shiftReducer";
import productsPaginationReducer from "./productsPaginationReducer";
import sizesReducer from "./sizesReducer";
import producstLoadingReducer from "./producstLoadingReducer";

export default combineReducers({
    groups: groupsReducer,
    brands: brandsReducer,
    deviceKey: deviceKeyReducer,
    filters: filtersReducer,
    products: productsReducer,
    productsPagination: productsPaginationReducer,
    productsLoading: producstLoadingReducer,
    genders: gendersReducer,
    productModal: productModalReducer,
    shift: shiftReducer,
    sizes: sizesReducer,
});
