import { FETCH_PRODUCTS, PRODUCTS_LOADING } from "../actions/types";

export default (state = false, { type }) => {
    switch (type) {
        case PRODUCTS_LOADING:
            return true;
        case FETCH_PRODUCTS:
            return false;
        default:
            return state;
    }
}
