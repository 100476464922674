import React from 'react';
import {Header, Segment, Table} from "semantic-ui-react";

class ProductModalSizeTable extends React.Component {
    renderTable() {
        const { product } = this.props;

        if (!product) {
            return '';
        }

        const sizes = { EU: [], CM: [], US: [], UK: [] };

        product.items.forEach(item => {
            sizes.EU.push(item.sizes.eu);
            sizes.CM.push(item.sizes.cm);
            sizes.US.push(item.sizes.us);
            sizes.UK.push(item.sizes.uk);
        });

        const rows = Object.keys(sizes).map(key => {
            const sizeCells = sizes[key].map((size, index) => {
                return <Table.Cell key={index}>{size}</Table.Cell>;
            });

            return (
                <Table.Row key={key}>
                    <Table.Cell>{key}</Table.Cell>
                    {sizeCells}
                </Table.Row>
            );
        });

        return (
            <Table textAlign={'center'} definition>
                <Table.Body>{rows}</Table.Body>
            </Table>
        );
    }

    render() {
        return (
            <Segment>
                <Header>Tabela rozmiarów</Header>
                {this.renderTable()}
            </Segment>
        );
    }
}

export default ProductModalSizeTable;
