import natshop from "../apis/natshop";
import { FETCH_FILTERS } from "./types";

export default () => async dispatch => {
    const groupsResponse = await natshop.get('/groups');
    const brandsResponse = await natshop.get('/producers');
    const gendersResponse = await natshop.get('/genders');

    const sizesResponse = await natshop.get('/sizes');
    const shoeSizesResonse = await natshop.get('/sizes', {
        params: { 'group.id': [3, 14] }
    });

    dispatch({
        type: FETCH_FILTERS,
        payload: {
            groups: groupsResponse.data.groups,
            brands: brandsResponse.data.producers,
            genders: gendersResponse.data.genders,
            sizes: sizesResponse.data,
            shoeSizes: shoeSizesResonse.data,
        }
    });
};
