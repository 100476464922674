import natshop from "../apis/natshop";
import { FETCH_PRODUCTS } from "./types";
import { clearProducts, productsLoading, updateCurrentFilters } from "./index";
import _ from 'lodash';

const filtersMap = {
    group: 'group.id',
    brand: 'producer.id',
    gender: 'sizeGroup.gender.id',
    size: 'mapping.sizeAvailable.soft.eu.match',
};

export default (filters = {}, clearCurrentProducts = false) => async (dispatch, getState) => {
    const currentFilters = {...filters, limit: 36};

    if (clearCurrentProducts && !_.isEqual(currentFilters, getState().filters)) {
        dispatch(clearProducts());
    }

    dispatch(updateCurrentFilters(currentFilters));
    dispatch(productsLoading());

    const requestParams = { page: 1 };

    _.forIn(currentFilters, (value, key) => {
        const paramKey = filtersMap[key] ? filtersMap[key] : key;

        if (Array.isArray(value)) {
            requestParams[paramKey] = value.map(val => {
                return 'object' === typeof val ? val.id : val;
            });
        } else {
            requestParams[paramKey] = 'object' === typeof value ? value.id : value;
        }
    });

    const response = await natshop.get('/products', { params: requestParams });

    dispatch({
        type: FETCH_PRODUCTS,
        payload: {
            responseData: response.data,
            filters: { ...filters, limit: requestParams.limit },
        },
    });
}
