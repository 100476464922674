import { FETCH_PRODUCTS, UPDATE_CURRENT_FILTERS } from "../actions/types";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case UPDATE_CURRENT_FILTERS:
            return payload;
        case FETCH_PRODUCTS:
            return payload.filters;
        default:
            return state;
    }
};
