import React from 'react';
import {Button, Header, Icon, Modal} from "semantic-ui-react";

class ItemShiftConfirmModal extends React.Component {
    state = {open: false};

    open = () => this.setState({open: true});
    close = () => this.setState({open: false});

    onConfirm = () => {
        this.close();
        this.props.onConfirm();
    };

    render() {
        const { product, item, children } = this.props;

        return (
            <Modal
                open={this.state.open}
                onOpen={this.open}
                onClose={this.close}
                trigger={children}
                basic
                size={'small'}
            >
                <Header><Icon name={'cart arrow down'}/>Potwierdź zamówienie</Header>
                <Modal.Content>
                    <p>Potwierdź przyniesienie rozmiaru {item.size} produktu {product.index}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color={'red'} inverted onClick={this.close}>
                        <Icon name={'remove'}/> Wróć
                    </Button>

                    <Button color={'green'} inverted onClick={this.onConfirm}>
                        <Icon name={'checkmark'}/>
                        Potwierdzam
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default ItemShiftConfirmModal;
