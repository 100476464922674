import React from 'react';
import {Button, Grid, Header, Image, Label, Message, Modal, Segment} from "semantic-ui-react";
import ReactAliceCarousel from "react-alice-carousel";
import {renderPrice} from "../../helpers";
import ProductModalSizeTable from "./ProductModalSizeTable";
import {connect} from 'react-redux';
import {clearShift, closeProductModal} from "../../actions";
import SizeButtons from "./SizeButtons";

class ProductModal extends React.Component {
    render() {
        if (this.props.product) {
            return this.renderRoot(this.props.product);
        }

        return '';
    }

    renderRoot = (product) => {
        const images = this.renderImages(product);

        return (
            <Modal open={!!this.props.product} onClose={this.onClose} size={'fullscreen'}>
                <Modal.Header style={{textTransform: 'uppercase'}}>
                    {product.group} {product.producer} {product.name}
                    <Label attached={'top right'} size={'massive'} color={'blue'}>{renderPrice(product.price)}</Label>
                </Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={8}>
                            <ReactAliceCarousel
                                buttonsDisabled={true}
                                autoPlay={images.length > 1}
                                autoPlayInterval={2000}
                            >{images}</ReactAliceCarousel>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            {this.renderApiMessage()}
                            <Segment>
                                <Header>Zamów rozmiar</Header>
                                <SizeButtons product={product} />
                            </Segment>
                            {this.renderDescription(product)}
                            <ProductModalSizeTable product={product} />
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.onClose} negative>Zamknij</Button>
                </Modal.Actions>
            </Modal>
        );
    };

    onClose = () => {
        this.props.closeProductModal();
        this.props.clearShift();
    };

    renderDescription = product => {
        return product.description
            ? <Segment><Header>Opis</Header><div dangerouslySetInnerHTML={{__html: product.description}} /></Segment>
            : '';
    };

    renderImages = product => {
        return product.images.map(image => {
            return <Image key={image.id} src={image.url} fluid />
        });
    };

    renderApiMessage = () => {
        const {shift} = this.props;

        if (!shift) {
            return '';
        }

        const isError = !!shift.response.error;
        const apiMessage = isError
            ? shift.response.error.message
            : `Nasz zespół otrzymał wiadomość! Numer Twojego zlecenia: ${shift.response.number}`;

        return (
            <Message
                icon={isError ? 'exclamation triangle' : 'checkmark'}
                header={isError ? 'Błąd :-(' : 'OK!'}
                content={apiMessage}
                positive={!isError}
                error={isError}
                onDismiss={this.props.clearShift}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        product: state.productModal,
        shift: state.shift,
    };
};
const actions = {
    closeProductModal,
    clearShift,
};

export default connect(mapStateToProps, actions)(ProductModal);
