import React, { Component } from 'react';
import { Card, Placeholder } from "semantic-ui-react";

class ProductCardPlaceholder extends Component {
    render() {
        return (
            <Card>
                <Placeholder><Placeholder.Image square /></Placeholder>
                <Card.Content>
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder.Line length={'short'} />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                            <Placeholder.Line length={'medium'} />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Card.Content>
                <Card.Content extra>
                    <Placeholder>
                        <Placeholder.Paragraph>
                            <Placeholder.Line length={'long'} />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Card.Content>
            </Card>
        );
    };
}

export default ProductCardPlaceholder;
