import React from 'react';
import ProductCard from "./ProductCard";
import { CardGroup } from "semantic-ui-react";
import { connect } from 'react-redux';
import { fetchProducts } from "../actions";
import ProductCardPlaceholder from "./ProductCardPlaceholder";
import IntersectionVisible from 'react-intersection-visible';

class ProductList extends React.Component {
    componentDidMount() {
        this.props.fetchProducts({ page: 1 });
    }

    render() {
        return (
            <div>
                <CardGroup centered>{this.renderProductCards()}</CardGroup>
                {this.renderShowMoreButton()}
            </div>
        );
    }

    renderProductCards = () => {
        const productCards = [];

        this.props.products.forEach(product => {
            productCards.push(
                <ProductCard
                    key={product.id}
                    product={product}
                />
            );
        });

        if (this.props.productsLoading) {
            for (let i = 0; i < this.props.filters.limit; i++) {
                productCards.push(
                    <ProductCardPlaceholder key={`placeholder_${i}`} />
                );
            }
        }

        return productCards;
    };

    renderShowMoreButton = () => {
        const { pages, page } = this.props.pagination;

        if (pages > page) {
            const nextPage = page + 1;

            return (
                <IntersectionVisible
                    onShow={() => this.onShowMoreClick(nextPage)}
                >
                    <div>&nbsp;</div>
                </IntersectionVisible>
            );
        } else {
            return '';
        }
    };

    onShowMoreClick = page => {
        this.props.fetchProducts({ ...this.props.filters, page });
    };
}

const mapStateToProps = state => {
    return {
        products: state.products,
        productsLoading: state.productsLoading,
        pagination: state.productsPagination,
        filters: state.filters,
    };
};
const actions = { fetchProducts };

export default connect(mapStateToProps, actions)(ProductList);
