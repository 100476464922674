import React from 'react';
import { Container } from "semantic-ui-react";
import Navigation from "./navigation/Navigation";
import ProductList from "./ProductList";
import { connect } from "react-redux";
import { closeProductModal, fetchProducts, openProductModal, setupDeviceKey } from "../actions";
import ProductModal from "./productModal/ProductModal";
import IdleTimer from "react-idle-timer";

class App extends React.Component {
    idleTimer = null;

    componentDidMount() {
        this.props.setupDeviceKey();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (1 === this.props.products.length) {
            this.props.openProductModal(this.props.products[0]);
        }
    }

    onIdle = () => {
        this.props.fetchProducts({});
        this.props.closeProductModal();
        window.scroll(0, 0);
    };

    render() {
        return (
            <IdleTimer
                ref={ref => this.idleTimer = ref}
                element={document}
                onIdle={this.onIdle}
                timeout={60 * 1000}
            >
                <Navigation />
                <Container style={{marginTop: '5em'}} fluid textAlign={'center'}>
                    <img src="/banner_1920x108-237.jpg" alt="banner" style={{width: '100%', height: 'auto', marginBottom: '1em'}} />
                    <ProductList />
                </Container>
                <ProductModal />
            </IdleTimer>
        );
    }
}

const mapStateToProps = state => {
    return { deviceKey: state.deviceKey, products: state.products };
};
const actions = {
    setupDeviceKey,
    fetchProducts,
    openProductModal,
    closeProductModal,
};

export default connect(mapStateToProps, actions)(App);
