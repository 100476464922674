import React from 'react';
import ItemShiftConfirmModal from "./ItemShiftConfirmModal";
import {Button} from "semantic-ui-react";
import {connect} from 'react-redux';
import {clearShift, postShift} from "../../actions";

class SizeButtons extends React.Component {
    state = {itemClicked: null};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.shift && this.props.shift) {
            this.setState({itemClicked: null});
        }
    }

    render() {
        const {product} = this.props;
        const buttons = product.items.map(item => {
            return (
                <ItemShiftConfirmModal
                    key={item.id}
                    product={product}
                    item={item}
                    onConfirm={() => this.onShiftConfirm(item)}
                >
                    <Button
                        primary
                        style={{marginBottom: '5px'}}
                        loading={item === this.state.itemClicked}
                        disabled={item === this.state.itemClicked}
                    >{item.size}</Button>
                </ItemShiftConfirmModal>
            )
        });

        return <div>{buttons}</div>;
    }

    onShiftConfirm = item => {
        this.props.clearShift();
        this.props.postShift(item.id, this.props.deviceKey);
        this.setState({itemClicked: item});
    }
}

const mapStateToProps = state => {
    return {
        shift: state.shift,
        deviceKey: state.deviceKey,
    };
};
const actions = {postShift, clearShift};

export default connect(mapStateToProps, actions)(SizeButtons);
