import { CLEAR_PRODUCTS, FETCH_PRODUCTS } from "../actions/types";

export default (state = [], { type, payload }) => {
    switch (type) {
        case FETCH_PRODUCTS:
            if (payload.filters.page > 1) {
                return [...state, ...payload.responseData.products];
            }

            return payload.responseData.products;
        case CLEAR_PRODUCTS:
            return [];
        default:
            return state;
    }
};
