export default (state = {page: 1, total: 0, limit: 15, pages: 0}, {type, payload}) => {
    if ('FETCH_PRODUCTS' === type) {
        const { page, total, limit } = payload.responseData;

        return {
            page,
            total,
            limit,
            pages: Math.ceil(total / limit),
        };
    }

    return state;
};
