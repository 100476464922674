export default (state = {all: [], shoes: []}, {type, payload}) => {
    if ('FETCH_FILTERS' === type) {
        return {
            all: payload.sizes,
            shoes: payload.shoeSizes,
        };
    }

    return state;
};
